import React from 'react';
import Slider from 'react-slick';
import './ReviewCarousel.css'; // Custom styles for carousel if needed
import StarRating from './StarRating';

const ReviewCarousel = ({ reviews }) => {
  const reviewsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    //slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="review-carousel">
      <div className="col1">
        <div className="home__hero-text-wrapper1">
          <Slider {...reviewsSettings}>
            {reviews.map((review, index) => (
              <div key={index} className="review-slide">
                <div className="review-author">
                  <img alt='' src={review.profile_photo_url}></img>
                  <strong>{review.author_name}</strong>
                  <h5>{review.relative_time_description}</h5>
                </div>
                <div className="review-rating1">
                    <StarRating rating={review.rating} origin='reviewCarousel' />
                </div>
                <p className="review-text">{review.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousel;
