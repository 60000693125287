export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: "Roadaxle Truck Parts Limited",
  descriptionOne: `Welcome to Roadaxle Truck Parts Limited, your trusted partner in the trucking industry. Since our inception,
  we have dedicated ourselves to providing high-quality, reliable axle and truck parts to keep your fleet running smoothly and efficiently.`,
  descriptionTwo: `At Roadaxle Truck Parts Limited, our mission is simple: to deliver exceptional products and unparalleled service to our customers.
  We understand the critical role that well-maintained truck parts play in the success of your business, and we are committed to ensuring you receive only the best.`,
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "100% SECURE",
  headline: "Stay protected 24/7",
  description:
    "We've got you covered no matter where you are. Over 140 locations worldwide to ensure you have access anytime.",
  buttonLabel: "Learn More",
  imgStart: "",
  src: "images/svg-5.svg",
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "EASY SETUP",
  headline: "Super fast and simple onboarding process",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: "start",
  src: "images/svg-7.svg",
  alt: "Vault",
};
