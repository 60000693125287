// src/components/GoogleReviews.js

import React, { useEffect, useState } from 'react';
import { fetchGoogleReviews, calculateAverageRating, getTotalReviews  } from './services/googleReviewsService';
import StarRating from './StarRating';
import ReviewCarousel from './ReviewCarousel';

const GoogleReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totReviews, settotalReviews] = useState(0);

  useEffect(() => {
    const getReviews = async () => {
      const reviewsData = await fetchGoogleReviews();
      setReviews(reviewsData);

      const avgRating = calculateAverageRating(reviewsData);
      setAverageRating(avgRating);

      const totalReviews = getTotalReviews(reviewsData);
      settotalReviews(totalReviews);
    };

    getReviews();
  }, []);

  return (
    <div>
      <h2 className="heading1">
        Google Reviews
      </h2>
      <div>
        <StarRating rating={averageRating} origin='googleReviews' />
        <h6 className="headingh6">Based on <b>{totReviews} <img src='./images/others/googleimage.webp' alt='' width="50px"></img> reviews</b></h6>
      </div>
      {/* <ul>
        {reviews.map((review, index) => (
          <li key={index}>
            <strong>{review.author_name}</strong>
            <p>{review.text}</p>
            <small>Rating: {review.rating}</small>
          </li>
        ))}
      </ul> */}
      <div>
        <ReviewCarousel reviews={reviews} />
      </div>
    </div>
  );
};

export default GoogleReviews;
