import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";
import { TfiLocationPin, TfiEmail } from "react-icons/tfi";
import { PiPhoneCallLight } from "react-icons/pi";

function Footer() {

  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          {/* <div className="footer-link-items first">
            <div className="footer-address">
              <img
                src="/images/logo.svg"
                alt="logo"
                style={{ width: "205px", paddingRight: "20px" }}
              />
            </div>
          </div> */}
          <div className="footer-link-items first">
            <h2>Stay Connected</h2>
            <div className="social-icons">
              <Link
                to="/"
                target="_blank"
                aria-label="Facebook"
                className="social-icon-link facebook"
              >
                <FaFacebook size={27}/>
              </Link>
              <Link
                to="/"
                target="_blank"
                aria-label="Instagram"
                className="social-icon-link instagram"
              >
                <FaInstagram size={27}/>
              </Link>
            </div>
          </div>
          <div className="footer-link-items second">
            <h2>Get In Touch</h2>
            <div className="contact-icons">
              <Link
                to={`tel:${'+1(519)621-7779'}`}
                target="_blank"
                className="social-icon-link"
              >
                <PiPhoneCallLight size={21}/> &nbsp; +1(519)621-7779
              </Link>
              <Link
                to={`mailto:${'info@roadaxletruckpartslimited.com'}`}
                target="_blank"
                className="social-icon-link"
              >
                <TfiEmail size={21}/> &nbsp; info@roadaxletruckpartslimited.com
              </Link>
              <Link
                to="/"
                target="_blank"
                className="social-icon-link"
              >
                <TfiLocationPin size={21}/> &nbsp; 131 Sheldon Drive, Unit 13, <br />Cambridge, ON, N1R 6S2
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-info">
					<span className="">&copy; {new Date().getFullYear()} Roadaxle Truck Parts Limited.
          <h6>Powered by: <a href="https://www.code-commit.com"><b><u>Code Commit Inc.</u></b></a></h6>
          </span>
				</div>
    </div>
  );
}

export default Footer;
