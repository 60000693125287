export const menuItemsData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Products",
    submenu: [
      {
        title: "Air Accessories",
        url: "/products/airAccessories",
      },
      {
        title: "Bearings",
        url: "/products/bearings",
      },
      {
        title: "Cargo Control, Tarps & Winches",
        url: "/products/cargo",
      },
      {
        title: "Antifreeze & Windshield Washers",
        url: "/products/chemicals",
      },
      {
        title: "Disc Brake System",
        url: "/products/disc",
      },
      {
        title: "Drum Brake System",
        url: "/products/drum",
      },
      {
        title: "Seals",
        url: "/products/seals",
      },
      {
        title: "Suspension",
        url: "/products/suspension",
      },
    ],
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];
