import axios from 'axios';

const API_KEY = 'AIzaSyCuoyLEnw2uAmbageT6KaoLVtCdUwFGE1M'; // Replace with your actual API key
const PLACE_ID = 'ChIJbZAXE9V1LIgR2xCcFfUVEJ0'; // Replace with your actual Place ID

export const fetchGoogleReviews = async () => {
  try {
    const response = await axios.get(
      `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&fields=reviews&key=${API_KEY}`
    );
    return response.data.result.reviews || [];
  } catch (error) {
    console.error('Error fetching Google Reviews:', error);
    return [{
      "author_name": "Guardian Of the galaxy",
      "author_url": "https://www.google.com/maps/contrib/115355501037789815016/reviews",
      "language": "en",
      "original_language": "en",
      "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjXUHa47eO5wF5faXcNe7HmuHjyf_2cPtgX9PhB5Lrv9O3KCw0O2Pg=s128-c0x00000000-cc-rp-mo-ba3",
      "rating": 5,
      "relative_time_description": "a month ago",
      "text": "Safe parking and respectful staff",
      "time": 1720532558,
      "translated": false
    },
    {
      "author_name": "Gurpreet Singh",
      "author_url": "https://www.google.com/maps/contrib/109647680256464345648/reviews",
      "language": "en",
      "original_language": "en",
      "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJHebvqrm6YKLd4hFrrU0dxM6239c86Ib_gC4m7U6JrMGaYPg=s128-c0x00000000-cc-rp-mo",
      "rating": 5,
      "relative_time_description": "7 months ago",
      "text": "Had a visit to the shop recently\nGreat service and hospitality",
      "time": 1702831826,
      "translated": false
    }];
  }
};

export const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0;
  
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  export const getTotalReviews = (reviews) => {
    if (reviews.length === 0) return 0;

    return reviews.length;
  };