import React from 'react';
import './StarRating.css'; // Add custom styles for stars if needed

const StarRating = ({ rating, origin }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div className={origin === 'googleReviews' ? "star-rating" : "star-rating1"}>
      {[...Array(fullStars)].map((_, i) => (
        <span key={`full-${i}`} className="star full">&#9733;</span>
      ))}
      {halfStar && <span className="star half">&#9733;</span>}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`} className="star empty">&#9734;</span>
      ))}
    </div>
  );
};

export default StarRating;
